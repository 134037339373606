import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ModalController, NavController, NavParams } from '@ionic/angular';
import { ToastrService } from 'ngx-toastr';
import { OverlayService } from 'src/app/service/overlay.service';
import { ServicesProvider } from 'src/app/service/services';

@Component({
  selector: 'app-detalhes-acompanhamento-especial',
  templateUrl: './detalhes-acompanhamento-especial.page.html',
  styleUrls: ['./detalhes-acompanhamento-especial.page.scss'],
})
export class DetalhesAcompanhamentoEspecialPage implements OnInit {

  public form: FormGroup;
  public data:any = null;
  public user_login: any = [];
  constructor(
    public formBuilder: FormBuilder, 
    private nav:NavController,
    private modalCtrl:ModalController,
    private navParams: NavParams,
    private service: ServicesProvider,
    private overlayService: OverlayService,
    private toastr: ToastrService,
  ){
  }

  ngOnInit() {
    console.log(this.data);
    this.infoUser();
  }

  infoUser(){
    this.user_login = JSON.parse( localStorage.getItem('id_token_claims_obj') )
  }
  closeModal(result){
    this.modalCtrl.dismiss(result);
  }
 
  formatarCPFCNPJ(cpfCnpj) {
     if (cpfCnpj.length === 11) {
      return cpfCnpj.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    } else if (cpfCnpj.length === 14) { // Verifica se é um CNPJ
      return cpfCnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
    }
   }
}
