import { SharedModule } from 'src/app/shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { ControleTermoPage } from './controle-termo.page';



@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [ControleTermoPage],
  entryComponents:[ControleTermoPage]

})
export class ControleTermoPageModule {}
