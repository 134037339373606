import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RedirectPtaComponent } from './redirect-pta.component';
import { PipesModule } from 'src/app/pipe/pipes.module';
import { IonicModule } from '@ionic/angular';

@NgModule({
  declarations: [
    RedirectPtaComponent
  ],
  exports: [
    RedirectPtaComponent
  ],
  imports: [
    IonicModule,
    PipesModule,
  ]
})

export class RedirectPtaModule { }
