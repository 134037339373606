import { ToastrService } from 'ngx-toastr';
import { OverlayService } from './../../../service/overlay.service';
import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { ServicesProvider } from 'src/app/service/services';

@Component({
  selector: 'app-excluir-vinculos',
  templateUrl: './excluir-vinculos.page.html',
  styleUrls: ['./excluir-vinculos.page.scss'],
})
export class ExcluirVinculosPage implements OnInit {

  public item_excluido_id: any = [];
  public item_excluido_nome: any = [];
  public itemFoiExcluido: boolean = false;
  public itens: any = {};
  public user: any [];

  constructor(
    public modal: ModalController,
    public navparams: NavParams,
    public overlayService: OverlayService,
    private services: ServicesProvider,
    private toastr: ToastrService,
  ) { }

  ngOnInit() {
  }

  closeModal() {
    this.modal.dismiss(this.itemFoiExcluido);
  }

  removerVinculo() {
    
    let params: any ={
      'id': this.navparams.data.idParaExclusao,
      'usuario':this.navparams.data.usuarioParaExclusao
    }

    const loading = this.overlayService.loading()

    this.services.request('/vinculos/excluir', 'delete', params, true)
      .then(result => {
        if(result.meta.codigo == 200) {
          this.itemFoiExcluido = true
          this.toastr.success("Registro excluído com sucesso!")
          this.closeModal();
        }else if (result.meta.codigo == 400){
          this.toastr.error(result.meta.mensagem);
          this.closeModal();
        }
     })
    .catch(err => {
      console.log(err);
      this.toastr.error(err.message)
    })
    .finally(async () => (await loading).dismiss() )
  }

}
