import { SharedModule } from './../../../shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { ExcluirCarteiraPage } from './excluir-carteira.page';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    SharedModule
  ],
  declarations: [ExcluirCarteiraPage],
  entryComponents:[ExcluirCarteiraPage]
})
export class ExcluirCarteiraPageModule {}
