import { ToastrService } from 'ngx-toastr';
import { OverlayService } from './../../../service/overlay.service';
import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { ServicesProvider } from 'src/app/service/services';
@Component({
  selector: 'app-excluir-carteira',
  templateUrl: './excluir-carteira.page.html',
  styleUrls: ['./excluir-carteira.page.scss'],
})
export class ExcluirCarteiraPage implements OnInit {

  public item_excluido: any = [];
  public itemFoiExcluido: boolean = false;
  public itens: any = {};
  public carteira_nome: any =[];
  public user: any = [];

  constructor(
    public modal: ModalController,
    public navparams: NavParams,
    public overlayService: OverlayService,
    private services: ServicesProvider,
    private toastr: ToastrService,

  ) { }

  ngOnInit() {
    this.carteira_nome = this.navparams.data.itemSelected.nome
    this.user = JSON.parse( localStorage.getItem('id_token_claims_obj') );
    
  }

  closeModal() {
    this.modal.dismiss(this.itemFoiExcluido);
  }

  removerCarteira() {
    this.item_excluido = this.navparams.data.idParaExclusao

    let params = {
      'id': this.item_excluido,
      'cpf': this.user.cpf
    }
    const loading = this.overlayService.loading()

    this.services.request('/carteira/excluir', 'delete', params, true)
      .then(result => {
        if(result.meta.codigo == 200) {
          this.itemFoiExcluido = true
          this.toastr.success("Carteira excluída com sucesso!")
          this.closeModal();
        }
     })
    .catch(err => {
      console.log(err);
      this.toastr.error(err.message)
    })
    .finally(async () => (await loading).dismiss() )
  }

}
