import { Router } from '@angular/router';
import { AppStorageProvider } from 'src/app/service/app-storage-provider';
import { ModalController } from '@ionic/angular';
import { Component, OnInit } from '@angular/core';
import { AutenticacaoService } from 'src/app/core/autenticacao.service';
import { OAuthService } from "angular-oauth2-oidc";

@Component({
  selector: 'app-controle-termo',
  templateUrl: './controle-termo.page.html',
  styleUrls: ['./controle-termo.page.scss'],
})
export class ControleTermoPage implements OnInit {
  constructor(
    public modalController: ModalController,
    public storage: AppStorageProvider,
    public router: Router,
    private oauth: AutenticacaoService,
    private oauthService: OAuthService,

  ) { }

  ngOnInit() {
  }

  closeModal(){
    this.modalController.dismiss()
  }

  logout(){
    this.storage.clearStorage();
    this.oauthService.logOut();

  }
  getPage(page, item, obj = "temp") {
    console.log(page)
    this.storage.removeTemp();
    this.storage.setTemp({ [obj]: item });
    this.router.navigate([page]);
  }

}
