import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { OverlayService } from 'src/app/service/overlay.service';
import { ServicesProvider } from 'src/app/service/services';
import { AppStorageProvider } from 'src/app/service/app-storage-provider';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-incluir-vinculos',
  templateUrl: './incluir-vinculos.page.html',
  styleUrls: ['./incluir-vinculos.page.scss'],
})
export class IncluirVinculosPage implements OnInit {

  public myForm: FormGroup;
  public submitted = false;
  public user: any = [];
  public check_cpf = false;
  public check_cnpj = false;

  constructor(
    public modal: ModalController,
    public formBuilder: FormBuilder,
    private overlayService: OverlayService,
    private service: ServicesProvider,
    private navparams: NavParams,
    public storage: AppStorageProvider,
    private toastr: ToastrService,
  ) {
    this.myForm = this.formBuilder.group({
      cpf:[0,Validators.required],
      cnpj:[0,Validators.required],
      descricao: ['',Validators.required],
    });
  }

  ngOnInit() {
    this.user = JSON.parse( localStorage.getItem('id_token_claims_obj') )
  }

  closeModal() {
    this.modal.dismiss();
  }

  incluir(){
    console.log(this.myForm.value)
    let params = {
     'cpf': this.myForm.value.cpf,
     'cnpj': this.myForm.value.cnpj,
     'descricao': this.myForm.value.descricao,
     'usuario': this.user.name
     }
    console.log('parametros de cadastro: ',params)
    this.service.request('/vinculos/incluir', 'post', params, true)
    .then(rs=>{
      this.submitted = true;
      console.log('rs: ',rs)
      if(rs.meta.codigo == 200){
        this.toastr.success('Registro gravado com sucesso!')
        this.closeModal();
      }else
       this.toastr.error("Erro ao realizar cadastro.")
    }).catch(err=>{
      console.log('erro: ', err)
      this.toastr.error('Tipo de vínculo já existe. Verifique.')
      this.closeModal();
    })
  }

  checked_cpf(checkbox: HTMLInputElement){
    if (checkbox.checked == false) {
      this.myForm.value.cpf = 1
    }
    if (checkbox.checked == true) {
      this.myForm.value.cpf = 0
    }
  }

  checked_cnpj(checkbox: HTMLInputElement){
    if (checkbox.checked == false) {
      this.myForm.value.cnpj = 1
    }
    if (checkbox.checked == true) {
      this.myForm.value.cnpj = 0
    }
  }

}
