import { NgModule } from '@angular/core';
// import { Routes, RouterModule } from '@angular/router';
import { SharedModule} from "src/app/shared/shared.module";

//import { IonicModule } from '@ionic/angular';

import { ModalVisualizarPage } from './modal-visualizar.page';

// const routes: Routes = [
//   {
//     path: '',
//     component: ModalInserirVinculosPage
//   }
// ];

@NgModule({
  imports: [
    SharedModule,
    //IonicModule,
    // RouterModule.forChild(routes)
  ],
  declarations: [ModalVisualizarPage],
  entryComponents: [ModalVisualizarPage]
})
export class ModalVisualizarPageModule {}
