import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-modal-anotacoes-jucemg',
  templateUrl: './modal-anotacoes-jucemg.page.html',
  styleUrls: ['./modal-anotacoes-jucemg.page.scss'],
})
export class ModalAnotacoesJucemgPage implements OnInit {

  public data:any = null;

  constructor(

 
    public formBuilder: FormBuilder,
    public modalController: ModalController,
   
  ) { }

  ngOnInit() {

  }


  closeModal() {
    this.modalController.dismiss();
  }
}
