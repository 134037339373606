import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams, Events } from '@ionic/angular';
// import * as Const from '../../service/constantes';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {
  public itens: any = {};
  public type: any;
  public title: string;
  public keys: any = [];
  // public t: any = Const.translate;

  constructor(private navParams: NavParams, public modalController: ModalController) {
    this.itens = navParams.get('item');
    this.type = navParams.get('type');
    this.title = this.type.title;

    this.keys = Object.keys(this.itens);
    console.log(this.itens);
  }

  ngOnInit() {}
}
