import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ModalController, NavController, NavParams } from '@ionic/angular';
import { ToastrService } from 'ngx-toastr';
import { OverlayService } from 'src/app/service/overlay.service';
import { ServicesProvider } from 'src/app/service/services';

@Component({
  selector: 'app-excluir-acompanhamento-especial',
  templateUrl: './excluir-acompanhamento-especial.page.html',
  styleUrls: ['./excluir-acompanhamento-especial.page.scss'],
})
export class ExcluirAcompanhamentoEspecialPage implements OnInit {
	
  public form: FormGroup;
  public data:any = null;
  public user_login: any = [];
  constructor(
    public formBuilder: FormBuilder, 
    private nav:NavController,
    private modalCtrl:ModalController,
    private navParams: NavParams,
    private service: ServicesProvider,
    private overlayService: OverlayService,
    private toastr: ToastrService,
  ){
  }
  

  ngOnInit() {
    this.infoUser();
  }

  infoUser(){
    this.user_login = JSON.parse( localStorage.getItem('id_token_claims_obj') )
  }

  excluirRegistro(){  

    if(Array.isArray(this.data)){
      
      let acompanhamentos = []

      this.data.forEach(element => {
        acompanhamentos.push(
        {
          'cpf_cnpj' : element.cpf_cnpj,
          'inclusao' : element.inclusao,
          'cpf_usuario' : element.cpf_usuario,
        }
      )
      });
      this.service.request('/acompanhamento/excluir-historico', 'delete' , {'acompanhamentos' : JSON.stringify(acompanhamentos)} , true).then(rs => {
        if(rs.meta.codigo == 200){
          this.toastr.success("Registros excluídos com sucesso!")
          this.closeModal(true)
        }else{
          this.toastr.error("Erro ao excluir");
        }
  
      }).finally(() => {  })


    }else{
 
    let params = {
      'cpf_cnpj' : [this.data.cpf_cnpj],
      'inclusao' : this.data.inclusao
    }
    
    this.service.request('/acompanhamento/excluir', 'delete', params, true).then(rs => {
      if(rs.meta.codigo == 200){
        this.toastr.success("Registro excluído com sucesso!")
        this.closeModal(true)
      }else{
        this.toastr.error("Erro ao cadastrar notificação");
      }

    }).finally(() => {  })
  }
    
  }
  
  closeModal(value){
    this.modalCtrl.dismiss(value);
  }

}
