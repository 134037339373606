import { Component, OnInit } from '@angular/core';
import { NavController,ModalController, NavParams } from '@ionic/angular';
import {
	Validators,
	FormBuilder,
	FormGroup,
	FormControl,
	AbstractControl
} from '@angular/forms';
import { ServicesProvider } from 'src/app/service/services';
import { OverlayService } from 'src/app/service/overlay.service';
import { ToastrService } from 'ngx-toastr';

@Component({
	selector: 'app-modal-visualizar',
	templateUrl: './modal-visualizar.page.html',
	styleUrls: ['./modal-visualizar.page.scss'],
})
export class ModalVisualizarPage implements OnInit {
		
		public form: FormGroup;
		public data:any = null;
		public user_login: any = [];
		constructor(
			public formBuilder: FormBuilder, 
			private nav:NavController,
			private modalCtrl:ModalController,
			private navParams: NavParams,
			private service: ServicesProvider,
			private overlayService: OverlayService,
			private toastr: ToastrService,
		){
		}
		


	
		ngOnInit() {
			this.infoUser();
		}
	
		infoUser(){
			this.user_login = JSON.parse( localStorage.getItem('id_token_claims_obj') )
		}
		closeModal(result){
			this.modalCtrl.dismiss(result);
		}
		
	
	}
